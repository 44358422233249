<template>
  <div>
    <div v-if="loading" class="text-center">
      <Loading></Loading>
    </div>
    <iframe v-else width="100%" height="1200px" :src="src" />
  </div>
</template>

<script>
import ExportPDF from "../components/Exports/exportPDF";
import Loading from "../components/shared/loading/index.vue";
export default {
  mixins: [ExportPDF],
  data() {
    return {
      loading: false,
      reportDetail: {},
      emailAddresses: [],
    };
  },
  computed: {
    src() {
      if (this.file !== null) return URL.createObjectURL(this.file);
      return "";
    },
  },
  methods: {
    async GET_REPORT_DETAIL() {
      this.loading = true;
      const id = this.$route.query.id;
      if (id === undefined) this.$router.push("/error");
      const response = await this.$api.get(`CompanyReports/${id}`, "", false);
      console.log(response);
      if (response.result === "OK" && response.message === "OK") {
        this.reportDetail = response.data;
        if (response.data.isCompleted === 0)
          this.showIncompleteReportAlertModal = true;
        setTimeout(async () => {
          console.log(this.reportDetail);
          await this.SET_EXPORT_PDF(this.reportDetail, true);
          window.location.href = this.src;
        }, 150);
      } else this.$router.push("/");
      this.loading = false;
    },
  },
  mounted() {
    this.GET_REPORT_DETAIL();
  },
  components: { Loading },
};
</script>

<style>
</style>